<template>
  <v-dialog v-model='open' max-width='550px' persistent scrollable>
    <v-card>
      <v-card-title class='d-flex flex-column justify-start'>
        <span class='headline'>Donate to {{ item.charityName }}</span>
      </v-card-title>
      <span class='commission-text'>
        {{ commission }}% is added to donations to support the MCBA mission and continue their work in our community. The {{ commission }}% support to MCBA may not be tax deductible. Please consult your tax professional for proper guidance.
      </span>
      <v-card-text class='py-2 px-4 overflow-y-hidden'>
        <v-container>
          <v-form ref='form' v-model='valid' class='modalForm'>
            <v-row>
              <v-col>
                <p class='font-weight-bold label'>Amount</p>
                <v-currency-field
                  ref='amount'
                  v-model='cardData.amount'
                  :rules='amountRules'
                  class='currencyInput'
                  outlined
                  prefix='$'
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class='font-weight-bold label'>Recurrence</p>
                <v-select
                  ref='recurrence'
                  v-model='recurrence'
                  :items='selectItems'
                  :rules='recurrenceRules'
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class='font-weight-bold label'>Card Number</p>
                <v-text-field
                  ref='number'
                  v-model='cardData.number'
                  :rules='numberRules'
                  maxlength='16'
                  oninput='if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)'
                  outlined
                  required
                  type='number'
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row md='4' sm='6'>
              <v-col class='d-flex justify-space-between'>
                <v-menu
                  v-model='menuExpDate'
                  :close-on-content-click='false'
                  :nudge-right='40'
                  min-width='auto'
                  offset-y
                  transition='scale-transition'
                >
                  <template v-slot:activator='{ on, attrs }'>
                    <div>
                      <p class='font-weight-bold label'>Expiration Date</p>
                      <v-text-field
                        ref='expDate'
                        v-model='cardData.expDate'
                        :rules='expDateRules'
                        class='pt-0'
                        prepend-icon='mdi-calendar'
                        readonly
                        v-bind='attrs'
                        v-on='on'
                      ></v-text-field>
                    </div>
                  </template>
                  <v-date-picker
                    v-model='cardData.expDate'
                    type='month'
                    @input='menuExpDate = false'
                  ></v-date-picker>
                </v-menu>
                <div>
                  <p class='font-weight-bold label'>CVC</p>
                  <v-text-field
                    ref='cvc'
                    v-model='cardData.cvc'
                    :rules='cvcRules'
                    maxlength='3'
                    oninput='if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)'
                    outlined
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-subtitle v-if='cardData.amount !== 0' class='pb-0 d-flex justify-space-between'>
        <p class='commission-detail'>
          <strong>MCBA Support:</strong> {{ getCommission }}
        </p>
        <p class='commission-detail mb-0'>
          <strong>Total:</strong> {{ getTotalAmount }}
        </p>
      </v-card-subtitle>
      <v-card-actions class='d-flex direction-row justify-center'>
        <v-btn
          class='px-14 ma-4 font-weight-bold'
          color='blue'
          large
          outlined
          @click='closeDialog'
        >
          Cancel
        </v-btn>
        <v-btn
          :loading='loadingDonate'
          class='px-14 ma-4 font-weight-bold white--text'
          color='primary'
          large
          @click='donate'
        >
          Donate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import donationService from '../../../services/donations/index'
import employeeProfileService from '../../../services/employeeProfile/index'
import {mapGetters} from 'vuex'

export default {
  props: ['open', 'item'],
  data() {
    return {
      loadingDonate: false,
      employee: {},
      valid: true,
      menuExpDate: false,
      recurrence: '',
      selectItems: ['One Time', 'Monthly'],
      cardData: {
        amount: 0,
        expDate: ''
      },
      amountRules: [(v) => !!v || 'Amount is required'],
      recurrenceRules: [(v) => !!v || 'Recurrence is required'],
      numberRules: [(v) => !!v || 'Card number is required'],
      expDateRules: [(v) => !!v || 'Expiration date is required'],
      cvcRules: [(v) => !!v || 'Cvc is required'],
      data: {},
      subscriptions: [],
      isSubscribe: false,
      charge: false
    }
  },
  computed: {
    ...mapGetters(['commission']),
    getCommission() {
      return this.formatMoney(this.cardData.amount * (this.commission / 100))
    },
    getTotalAmount() {
      return this.formatMoney(this.cardData.amount + (this.cardData.amount * (this.commission / 100)))
    }
  },
  async mounted() {
    await this.getEmployeeProfile()
  },
  async updated() {
    if (this.open && this.item && !this.charge) {
      this.charge = true
      await this.checkSubscription(this.$store.getters.user.profile_id.employee_id)
    }
  },
  methods: {
    closeDialog() {
      this.selectItems = ['One Time', 'Monthly']
      this.charge = false
      this.$emit('close')
      this.$refs.form.reset()
    },
    async donate() {
      try {
        if (this.$refs.form.validate()) {
          this.loadingDonate = true
          const data = {
            number: this.cardData.number,
            exp_month: this.cardData.expDate.split('-')[1],
            exp_year: this.cardData.expDate.split('-')[0],
            cvc: this.cardData.cvc,
            amount: this.cardData.amount * 100,
            donation_recurrence: this.recurrence,
            destination: this.item.charityId,
            description: 'Donation',
            charity_event_id: this.item.eventId,
            charity_id: this.item.charityId,
            employee_id: this.employee.id
          }
          let res
          if (this.recurrence === 'One Time') {
            res = await donationService.donate(data)
          } else {
            data.name = this.employee.fullName
            data.email = this.employee.email
            res = await donationService.suscription(data)
          }
          await this.handlerResponse(res, data)
          this.loadingDonate = false
        }
      } catch (e) {
        console.log(e)
        this.loadingDonate = false
      }
    },
    async handlerResponse(res) {
      // if (res.success) await this.createRequest(data)
      this.$emit('response', res)
      this.closeDialog()
    },
    async createRequest(data) {
      const request = {
        donationAmount: data.amount / 100,
        donationRecurrence: data.donation_recurrence,
        charityEventId: data.charity_event_id,
        date: new Date()
      }
      await donationService.createDonationRequest(request)
    },
    async getEmployeeId() {
      try {
        const userId = this.$store.getters.user.id
        const employee = await employeeProfileService.getEmployeeId({
          userId: userId
        })
        return employee.employeeId
      } catch (err) {
        console.log(err)
      }
    },
    async getEmployeeProfile() {
      const employeeId = await this.getEmployeeId()
      try {
        this.employee = await employeeProfileService.getEmployeeProfile(employeeId)
      } catch (err) {
        console.log(err)
      }
    },
    async checkSubscription(id) {
      try {
        this.subscriptions = await employeeProfileService.getSubscriptions(id)
        this.subscriptions.forEach((subs) => {
          if (subs.charityEventId === this.item.eventId && subs.status) {
            this.isSubscribe = true
            this.selectItems = ['One Time']
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    formatMoney(value) {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    }
  }
}
</script>

<style lang='scss'>
.commission-text {
  display: block;
  font-size: 0.8rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
  color: rgb(98, 97, 97);
  flex-flow: wrap;
  word-wrap: break-word;
}
.commission-detail {
  font-size: 1rem;
}
</style>
