<template>
  <v-container fluid>
    <v-card class='rounded-lg mb-5' elevation='3'>
      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-row>
            <v-col
              class='d-flex flex-column avatarSection pb-0'
              lg='3'
              md='3'
              sm='12'
            >
              <v-row>
                <v-col>
                  <div class='avatarCharity'>
                    <v-card-title class='pt-1'> {{ donation.charity_name }}</v-card-title>
                    <div @click='redirect(donation)'>
                      <v-avatar color='grey' size='120'>
                        <v-img :src='donation.logo'></v-img>
                      </v-avatar>
                    </div>
                    <div class='d-flex flex-column mb-6 progressBar'>
                      <v-row>
                        <span class='percentage'>{{ Math.round(donation.percentage) }}%</span>
                      </v-row>
                      <v-row class='progressLinaer'>
                        <v-progress-linear
                          :value='donation.percentage'
                          class='rounded-lg'
                          color='primary'
                          height='15'
                          rounded
                        ></v-progress-linear>
                      </v-row>
                      <v-row class='currencies'>
                        <span>$0,00</span>
                        <span>{{ formatMoney(donation.mount_required) }}</span>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class='tablesSection pb-0' lg='9' md='9' sm='12'>
              <v-row>
                <v-col>
                  <v-data-table
                    :headers='dialogHeaders'
                    :items='[donation]'
                    class='elevation-1 topDataTable'
                    hide-default-footer
                  >
                    <span> {{ donation.need }} </span>
                    <template #item.mount_required={item}>
                      <span> {{ formatMoney(item.mount_required) }} </span>
                    </template>
                    <template #item.total_donations={item}>
                      <span> {{ formatMoney(item.total_donations) }} </span>
                    </template>
                    <span> {{ donation.end }}  </span>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class='d-flex flex-row align-center'
                  lg='8'
                  md='8'
                  sm='12'
                >
                  <div v-if='donation.percentage < 50'>
                    <span>Donate now and help us reach our goal!</span>
                  </div>
                  <div v-else>
                    <span>We are almost there! Donate now and help us reach our goal!</span>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn
                    class='px-14 py-4 font-weight-bold white--text'
                    color='primary'
                    large
                    @click='openDialogDonation(donation)'
                  >
                    Donate
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  props: ['donation'],
  data() {
    return {
      dialogHeaders: [
        {
          text: 'Need',
          value: 'need',
          align: 'center',
          divider: true,
          click: ''
        },
        {
          text: 'Funds needed',
          value: 'mount_required',
          align: 'center',
          divider: true
        },
        {
          text: 'Amount Raised',
          value: 'total_donations',
          align: 'center',
          divider: true
        },
        {text: 'Deadline', value: 'end', align: 'center', divider: true}
      ]
    }
  },
  methods: {
    formatMoney(value) {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    },
    redirect(item) {
      this.$store.dispatch('charity', item.charity_id)
      this.$router.push('/employee/charity-donations')
    },
    openDialogDonation(item) {
      this.$emit('openModalDonation', item)
    }
  }
}
</script>
<style></style>
