<template>
  <div v-frag>
    <v-container fluid>
      <v-alert
        v-model='alertOptions.show'
        :color='alertOptions.color'
        class='rounded-xl'
        dark
        dense
        dismissible
        icon='mdi-check-circle-outline'
        prominent
        transition='scale-transition'
        width='100%'
      >
        <h3 class='mt-2'>{{ alertOptions.title }}</h3>
        <p>{{ alertOptions.body }}</p>
      </v-alert>
    </v-container>
    <v-container fluid>
      <v-card class='text-center rounded-lg' elevation='3'>
        <v-card-title class='cardTitle'>
          Open Fundraising Opportunities
        </v-card-title>
        <v-card-subtitle>
          "Those who are happiest are those who do the most for others"
        </v-card-subtitle>
        <v-card-text> Booker T. Washington</v-card-text>
      </v-card>
    </v-container>
    <v-container fluid>
      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card :loading='cardLoader' class='rounded-lg' elevation='3'>
            <v-container fluid>
              <div v-for='donation in opportunities' :key='donation.id'>
                <CardEventDonation
                  :donation='donation'
                  v-on:openModalDonation='openDialogDonation'
                />
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <ModalDonation
      :item='itemToDonate'
      :open='dialogDonation'
      v-on:close='closeModalDonate'
      v-on:response='handleResponse'
    />
  </div>
</template>

<script>
import ModalDonation from './components/ModalDonate.vue'
import CardEventDonation from './components/CardEventDonation.vue'
import donationsService from '../../services/donations/index'

export default {
  components: {
    ModalDonation,
    CardEventDonation
  },
  data() {
    return {
      cardLoader: false,
      opportunitiesSearch: '',
      progressValue: 0,
      dialogDonation: false,
      options: {},
      alertOptions: {
        show: false
      },
      opportunitiesHeaders: [
        {
          text: 'Charity',
          align: 'start',
          value: 'charity_name',
          divider: true
        },
        {text: 'Need', value: 'need', divider: true},
        {text: 'Description', value: 'description', divider: true},
        {text: 'Funds Needed', value: 'mount_required', divider: true},
        {text: 'Amount Raised', value: 'total_donations', divider: true}
      ],
      dialogHeaders: [
        {text: 'Need', value: 'need', align: 'center', divider: true},
        {
          text: 'Funds needed',
          value: 'mount_required',
          align: 'center',
          divider: true
        },
        {
          text: 'Amount Raised',
          value: 'total_donations',
          align: 'center',
          divider: true
        },
        {text: 'Deadline', value: 'end', align: 'center', divider: true}
      ],
      donation: [
        {
          logo: ''
        }
      ],
      opportunities: [],
      fundsForCharity: [],
      itemToDonate: {}
    }
  },
  methods: {
    async initialize() {
      try {
        this.cardLoader = true
        const events = await donationsService.getAllDonations()
        this.opportunities = events.filter((event) => {
          if (event.event_type_id == 2 && event.bank_account_status == 1) {
            const percentage =
              Math.round(event.total_donations * 100) / event.mount_required
            event.percentage = percentage > 100 ? 100 : percentage
            return event
          }
        })
        this.fundsForCharity = events.filter(
          (event) => event.event_type_id == 3
        )
        this.cardLoader = false
      } catch (err) {
        this.cardLoader = false
      }
    },
    openDialogDonation(item = {}) {
      this.dialogDonation = true
      if (item.event_type_id) {
        this.itemToDonate = {
          eventId: item.id,
          charityId: item.charity_id,
          charityName: item.charity_name,
          type: 1
        }
      } else {
        this.fundsForCharity.forEach((event) => {
          if (event.charity_id == item.id) {
            this.itemToDonate = {
              eventId: event.id,
              charityId: event.charity_id,
              charityName: event.charity_name,
              type: 0
            }
          }
        })
      }
    },
    closeModalDonate() {
      this.dialogDonation = false
    },
    updateEventAmount(data) {
      this.opportunities.forEach((event) => {
        if (event.id == this.itemToDonate.eventId) {
          event.total_donations += data.amount / 100
          const percentage =
            Math.round(event.total_donations * 100) / event.mount_required
          event.percentage = percentage > 100 ? 100 : percentage
        }
      })
    },
    handleResponse(response) {
      if (response.success) {
        if (this.itemToDonate.type) {
          this.updateEventAmount(response.data)
        }
        this.openAlert(true)
      } else {
        this.openAlert(false, response.data.message)
      }
      this.itemToDonate = {}
    },
    openAlert(status, message = '') {
      window.scrollTo(0, 0)
      this.alertOptions = {
        show: true,
        color: 'success',
        title: 'Funds Donated',
        body: 'The donation was made successfully'
      }
      if (!status) {
        this.alertOptions.color = 'error'
        this.alertOptions.title = 'Something went wrong!'
        this.alertOptions.body = message
      }
    },
    redirect(item) {
      this.$store.dispatch('charity', item.id)
      this.$router.push('/employee/charity-donations')
    },
    formatMoney: function(value) {
      if (typeof value == 'number') {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    },
    formatToDate: (value) => {
      if (value) {
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    onChangeProgress(value) {
      this.progressValue = value
    }
  },
  created: function() {
    this.initialize()
  }
}
</script>

<style>

.cardTitle {
  display: block;
}

.avatarSection {
  padding-right: 1rem;
  margin-bottom: 0;
}

.tablesSection {
  padding-right: 1rem;
}

.donationSection {
  display: flex;
  align-items: center;
  height: 100px;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
}

.avatarCharity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-bottom: 0;
}

.avatarCharity .v-avatar {
  margin-bottom: 1.5rem;
  border-radius: 100% !important;
}

@media (min-width: 960px) and (max-width: 1024px) {
  .avatarCharity .v-avatar,
  .avatarCharity .v-avatar * {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
  }
}

.topDataTable {
  margin-bottom: 1.5rem;
}

.editNeedButton {
  width: 80px !important;
  height: 40px !important;
}

.descriptionText td {
  display: block;
  height: auto !important;
  padding: 16px !important;
  min-height: 90px !important;
  font-size: 14px;
  color: #666;
  font-weight: 400;
  line-height: 1.2;
}

@media (max-width: 991px) {
  .descriptionText td {
    min-height: inherit !important;
  }
}

@media (max-width: 1177px) {
  .progressBar {
    display: flex;
    flex-direction: row;
  }
}

.progressBar {
  align-items: center;
  margin-bottom: 0px !important;
  width: 100%;
}

.progressBar .v-progress-linear {
  margin: 8px 0;
}

.progressLinaer {
  width: 100%;
}

.progressBar .percentage {
  color: #1e88e5;
}

.progressBar .currencies {
  font-weight: 280;
  font-size: small;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  width: 100%;
}

.headerTable {
  border: 1px solid #eee;
}

.headerTable th td {
  text-align: center;
}

.border-left {
  border-left: 1px solid #eee;
}

.buttonHeader {
  display: flex;
  flex-direction: column;
  max-height: 5rem;
  margin-top: 1.5rem;
}

.currencyInput .v-input__slot {
  min-height: 45px !important;
  margin-bottom: 0;
}

.currencyInput v-text-field__details {
  margin-bottom: 0;
}

.closeButton {
  display: flex;
  justify-content: center;
  padding-bottom: -20 !important;
  margin-left: 10px;
}
</style>
