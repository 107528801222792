import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  getVolunteeringRequests: async(id) => {
    const response = await HTTP.get(
      '/employees/' + id + '/volunteering-requests',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  getPreviousRequests: async(id) => {
    const response = await HTTP.get('/employees/' + id + '/previous-requests', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getVotingRequests: async(id) => {
    const response = await HTTP.get('/employees/' + id + '/voting-requests', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getEmployeeId: async({userId}) => {
    const response = await HTTP.get(
      '/employees/users/' + userId + '/employee',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  getEmployeeProfile: async(id) => {
    const response = await HTTP.get('/employees/' + id + '/profile', {
      headers: helper.setHeaders()
    })
    return response.data.employee
  },
  getDonationHistory: async(id) => {
    const res = await HTTP.get('/employees/' + id + '/get/donations', {
      headers: helper.setHeaders()
    })
    return res.data.data
  },
  getSubscriptions: async(id) => {
    const res = await HTTP.get(`/employees/${id}/get/subscriptions`, {
      headers: helper.setHeaders()
    })
    return res.data.data
  },
  getElections: async(id) => {
    const response = await HTTP.get(
      '/elections/employees/' + id + '/vote-requests',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  getSubordinates: async({electionId, employeeId}) => {
    const response = await HTTP.get(
      '/employees/' + employeeId + '/subordinates-election/' + electionId,
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  getSubordinatesView: async({electionId, employeeId}) => {
    const response = await HTTP.get(
      '/employees/' + employeeId + '/subordinates-election-view/' + electionId,
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  editEmployee: async({
    id,
    firstName,
    middleName,
    lastName,
    userName,
    email,
    secondaryEmail,
    homePhone,
    workPhone,
    mobilePhone,
    address,
    city,
    state,
    latitude,
    longitude,
    vuid,
    zipCode,
    birthDate,
    hierarchy,
    reportsTo,
    profilePictureObject,
    emailNotification,
    smsNotificacion,
    fileType
  }) => {
    await HTTP.put(
      'employees/' + id + '/update-profile',
      {
        firstName,
        middleName,
        lastName,
        userName,
        email,
        secondaryEmail,
        homePhone,
        workPhone,
        mobilePhone,
        address,
        city,
        state,
        latitude,
        longitude,
        vuid,
        zipCode,
        birthDate,
        hierarchy,
        reportsTo,
        emailNotification,
        smsNotificacion
      },
      {
        headers: helper.setHeaders()
      }
    )
    if (profilePictureObject) {
      let formData = new FormData()
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      formData.append('profilePictureObject', profilePictureObject, {
        type: fileType
      })
      await HTTP.post('employees/' + id + '/upload-profile-picture', formData, {
        headers: headers
      })
    }
  }
}
